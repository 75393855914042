<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="all_lesson">
      <van-list v-show="isShow" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getMyBuyLesson">
        <div v-for="(lesson,index) in list" :key="index" class="main" @click="go_url(lesson)">
          <div class="lessons">
            <div class="content">
              <div class="box_top row_left" :class="nowDate>new Date(lesson.product_end_time).getTime()?'':'box_top_bor'">
                <div class="thumb">
                  <img v-if="lesson.thumb" class="img_left" :src="lesson.thumb">
                  <img v-else class="img_left" alt="暂无图片">
                </div>
                <div class="info_box">
                  <div class="title">
                    <p>
                      <span v-if="nowDate>new Date(lesson.product_end_time).getTime()" class="lesson_status">过期</span>
                      <span v-if="lesson.real_money==0" class="lesson_status free_status">免费</span>
                      {{ lesson.lesson_name }}
                    </p>
                  </div>
                  <!-- 已购课程 -->
                  <div class="midInfo">
                    <div class="expire">
                      <div v-if="lesson.product_end_time">{{ getTime(lesson.product_end_time).includes('2099')?'无限期':'有效期: '+getTime(lesson.product_end_time) }}</div>
                      <!-- <span style="margin: 0 5px;">|</span> -->
                      <div>已学习: <span style="color: #ee2e2e;">{{ lesson.looked_lesson_long }}</span>/{{ lesson.lesson_long }}课</div>
                    </div>
                    <!-- <p class="studyNoClass">
                      <span v-if="lesson.type==2 && lesson.is_add_class==2 && !isZkb">
                        <span>等待分班</span>
                      </span>
                    </p> -->
                  </div>
                </div>
              </div>
              <div v-if="new Date(lesson.product_end_time).getTime()>nowDate" class="botMenus">
                <div class="newBotMenusBg">
                  <div class="newCtrl">
                    <span class="recoverRecord" @click.stop="recoverRecord(lesson)">恢复记录</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <div v-show="!isShow" class="noDatas">
        <img src="@/static/images/my_index/kongzt.png" alt>
        <p v-html="noDataAlertInfo()"></p>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from 'js-cookie'
import heads from '@/compontens/heads.vue'
import { getLessonRecycleList, takeLessonBack } from '@/api/my_user.js'
import tool from '@/utils/tools'
import { Toast } from 'vant'

export default {
  components: { heads },
  data() {
    return {
      isLBKK: false,
      title_msg: '回收站',
      lesson_name: '',
      list: [],
      isshow: false,
      showMore: true,
      page: 1,
      limit: 10,
      loading: false,
      finished: false,
      isShow: true,
      isZkb: false,
      agency_id: '',
      active: '',
      type: 0,
      is_change: false,
      cookie_uid: Cookies.get('uid'),
      cookie_uname: Cookies.get('username'),
      // 是否是注考帮pro打开的页面：1 ：是
      zkb_pro: this.$route.query.zkb_pro || '',
      category_id: this.$route.query.category_id || '',
      category_name: decodeURIComponent(this.$route.query.category_name || ''),
      nowDate: new Date().getTime()
    }
  },
  mounted() {
    this.getMyBuyLesson(true)

    sessionStorage.setItem('edi_address_id', '')
  },
  created() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    if (this.zkb_pro == 1) {
      this.showMore = false
    }
    console.log('zkb_pro = ', this.zkb_pro)
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    const herf = window.location.href
    if (herf.indexOf('islbkk=true') != -1) {
      this.isLBKK = true
    } else {
      this.isLBKK = false
    }
    console.log(this.agency_id + ' ' + this.isZkb + ' ' + this.isLBKK + '-----------------')
  },
  methods: {
    // 恢复课程
    recoverRecord(val) {
      const p = {
        id: val.id
      }
      takeLessonBack(p).then(res => {
        if (res.errNo == 0) {
          Toast('恢复成功')
          this.list = []
          this.page = 1
          this.getMyBuyLesson(true)
        } else {
          Toast(res.msg)
        }
      })
    },
    // 无数据的提示语
    noDataAlertInfo() {
      var company = '回收站暂无内容~'
      var info_str = company
      return info_str
    },
    go_url(lesson) {
      if (this.isLBKK) {
        window.location.href = 'https://m.zk468.com/lbkk/study?lesson_id=' + lesson.lesson_id
      } else if (this.isZkb) {
        // 注考帮
        const origin = window.location.origin
        const lesson_url = `${origin}/kb_timetable?lesson_id=${lesson.lesson_id}&zkb_pro=1&category_id=${this.category_id}&category_name=${this.category_name}#i=2`
        window.location.href = lesson_url
      } else {
        var url = lesson.url + '#i=2'
        window.location.href = url
      }
    },
    getTime(data) {
      var str = data.slice(0, 10)
      return str
    },
    // 已购课程列表
    getMyBuyLesson(type) {
      // type = true 为切换执行
      // console.log(this.is_change + '-----' + type)

      if (type || this.is_change) {
        const p = {
          page: this.page,
          limit: this.limit,
          // type: this.type,
          zkb_pro: this.zkb_pro
        }
        getLessonRecycleList(p).then(res => {
          this.loading = false
          this.is_change = true

          const result = res.result ? res.result : {}
          const arr = result.list ? result.list : []
          for (let i = 0; i < arr.length; i++) {
            arr[i].showMoreLessonMenu = false
          }
          if (arr.length < this.limit) {
          // 么有了
            this.finished = true
          } else {
          // 还有
            this.finished = false
            this.page++
          }
          this.list = this.list.concat(arr)
          if (this.list.length > 0) {
            this.isShow = true
          } else {
            this.isShow = false
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },

    formatDate(time) {
      if (time !== null && time !== '') {
        time = time * 1000
        const date = new Date(time)
        return tool.formatDate(date, 'yyyy-MM-dd')
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.all_lesson{
  min-height: 100vh;
  padding:20px 30px;
  background: #f5f5f5;
  .main{
    margin-bottom: 20px;
  }
}
.noDatas {
  margin-top: 50%;
  text-align: center;
  img {
    margin: 0 auto;
    width: 250px;
    height: 250px;
    display: block;
  }
  p {
    font-family: PingFangSC-Regular;
    margin-top: 20px;
    font-size: 24px;
    color: #333;
  }
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.imgs {
  width: 251px;
  height: 155px;
  margin-left: 30px;
}
.dl {
  width: 100%;
  height: 210px;
  margin: 20px auto;
}
.dl dt {
  float: left;
}
.dl dd {
  float: right;
  // width: 420px;
  width: calc(100% - 310px);
}
.dls {
  border-bottom: #ececec 1px solid;
  position: relative;
}
.ke_name {
  font-size: 30px;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 30px;
}
.ke_yxq {
  color: #9d9ca1;
  line-height: 40px;
}
.ke_yxx {
  color: #d21b2e;
}
.fill_in {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 213px;
  height: 50px;
  border-radius: 25px;
  background-color: #ee2e2e;
  border: 1px solid #ee2e2e;
  color: #fff;
  text-align: center;
  line-height: 54px;
  font-size: 24px;
  float: right;
  position: absolute;
  right: 25px;
  bottom: 20px;
}

.threads {
  width: 100%;
  background-color: #fff;
  display: inline-block;
}
.content {
  padding: 12px 24px;
  border-bottom: 1px solid #eee;
}
.les_content {
  margin: 10px 30px 10px 30px;
  width: calc(100% - 60px);
  border-bottom: 1px solid #eee;
}
.box_top {
  display: flex;
  padding-bottom: 12px;

}
.box_top_bor{
  border-bottom: 1px solid #f5f5f5;
}
.row_left {
  flex-direction: row;
}
.row_right {
  flex-direction: row-reverse;
}
.thumb {
  margin-top: 10px;
  width: 278px;
  height: 145px;
  img {
    width: 258px;
    height: 145px;
    object-fit: cover;
    border-radius: 10px;
  }
  .img_left {
    margin-right: 20px;
  }
  .img_right {
    margin-left: 20px;
  }
}
.info_box {
  //自动计算剩余宽度
  flex: 1;
  position: relative;

  .lesson_status {
    background-color: #5a5a5a;
    color: #fff;
    font-size: 24px;
    padding: 1px 10px;
    border-radius: 6px;
    margin-right: 8px;
  }
  .free_status {
    background-color: #ee2e2e;
  }
  .midInfo {
    // 需要固定midInfo的离上间距，选择定位到父元素离底部的30px
    color: #999;
    font-size: 28px;
    margin-top: 8px;
    .expire {
      font-size: 24px;
      div{
        margin-top: 8px;
      }
    }
    .study {
      color: #ee2e2e;
      font-size: 24px;
    }
    .studyNoClass {
      text-align: right;
      color: #999;
      font-size: 24px;
    }
    .orders {
      font-size: 24px;
    }
    .orders_np {
      margin-top: 5px;
      text-align: right;
      color: #333;
      font-size: 24px;
    }
    .money {
      color: #ee2e2e;
      font-size: 24px;
    }
    .money1 {
      color: #333;
      font-size: 24px;
    }
  }
}
.title {
  // background-color: aqua;
  // height: 80px;
  padding-top: 8px;
  font-size: 30px;
  color: #333;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.botInfo {
  margin-top: 10px;
  height: 30px;
  display: flex;
  align-items: center;
}
.viewBox {
  height: 30px;
  display: flex;
  align-items: center;
}
.replyBox {
  height: 30px;
  display: flex;
  align-items: center;
}
.view {
  width: 28px;
  height: 18px;
}
.viewNum {
  display: inline-block;
  margin-left: 8px;
  color: #999;
  font-size: 24px;
}
.reply {
  margin-left: 30px;
  width: 26px;
  height: 22px;
}
.replyNum {
  display: inline-block;
  margin-left: 8px;
  color: #999;
  font-size: 24px;
}
.bot_time {
  width: 150px;
  height: 30px;
  line-height: 30px;
  text-align: right;
  color: #999;
  font-size: 24px;
  position: absolute;
  right: 30px;
}
.midInfo1 {
  margin-top: 8px;
  color: #999;
  font-size: 28px;
  display: flex;
  align-items: center;
  .student {
    display: flex;
    align-items: center;
  }
  .rate {
    display: flex;
    align-items: center;
  }
}
.studentIcon {
  width: 32px;
  height: 26px;
}
.rateIcon {
  margin-left: 30px;
  width: 29px;
  height: 29px;
}

.botLine {
  width: 100%;
  height: 0.5px;
  background-color: #eee;
}

.lessons {
  width: 100%;
  background-color: #fff;
  display: inline-block;
  border-radius: 16px;
}
.botMenus {
  padding-top: 12px;
  // margin-top: 18px;
  display: flex;
  align-items: center;
  height: 58px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  .botMenusBg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .recoverRecord{
      font-size: 26px;
      color: #666;
      margin-right: 20px;
    }
    .enterStudy{
      height: 50px;
      width: 130px;
      line-height: 50px;
      border: 2px solid #ee2e2e;
      display: inline-block;
      border-radius: 26px;
      background-color: #ee2e2e;
    }
  .botMenusLeft {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
    max-width: 600px;
    overflow-x: scroll;
    .menuScroll{
      white-space:nowrap;
      // width: 1200px;
    }
  }
  .newBotMenusBg{
    width: 100%;
  }
  .newCtrl{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .botMenusRight {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 8px;
      display: block;
    }
  }
  .span_edi_address {
    height: 48px;
    padding: 0px 12px;
    margin-right: 8px;
    line-height: 48px;
    display: inline-block;
    border-radius: 25px;
    background-color: white;
    color: #ee2e2e;
    border: 1px solid #ee2e2e;
  }
  .record {
    height: 48px;
    width: 200px;
    line-height: 50px;
    display: inline-block;
    margin-right: 20px;
    border-radius: 25px;
    color: #ee2e2e;
    border: 1px solid #ee2e2e;
  }
  .invoice {
    height: 48px;
    padding: 0px 12px;
    margin-right: 20px;
    line-height: 50px;
    display: inline-block;
    border-radius: 25px;
    color: #ee2e2e;
    border: 1px solid #ee2e2e;
  }
  .lesson_listen {
    height: 50px;
    width: 200px;
    line-height: 50px;
    display: inline-block;
    border-radius: 25px;
    background-color: #ee2e2e;
    margin-left: 25px;
  }
  .lesson_noclass {
    height: 50px;
    width: 130px;
    line-height: 50px;
    display: inline-block;
    border-radius: 25px;
    color: #999999;
    background-color: #fff;
    margin-left: 20px;
  }
  .noPayBtnsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-box-pack: end;
    -moz-justify-content: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .cancelPay {
    margin-right: 25px;
    color: #999;
    height: 50px;
    width: 130px;
    line-height: 50px;
    border: 2px solid #999;
    display: inline-block;
    border-radius: 10px;
    background-color: #fff;
  }
  .payNow {
    height: 50px;
    width: 130px;
    line-height: 50px;
    border: 2px solid #ee2e2e;
    display: inline-block;
    border-radius: 10px;
    background-color: #ee2e2e;
  }
}
.invoice1 {
  height: 50px;
  padding: 0px 24px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 25px;
  color: #ee2e2e;
  border: 1px solid #ee2e2e;
}
.div_card{
  box-shadow: 2px 2px 2px 2px #aaa9a9;
}
</style>
